import WebServiceRequest from '../Api/WebServiceRequest'

class TicketCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Ticket/CreateTicket')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class TicketDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Ticket/DeleteTicket?ticketId='+id)
    }
}
class TicketGetAllByParentIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag(`Ticket/GetAllByParentId?parentId=${data.id}`)
    }
}
class TicketGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod('GET')
        super.setTag('Tickets/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class TicketGetByIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod('GET')
        super.setTag('Tickets/GetTicketDetialsBy')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class AnswerTicket extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod('POST')
        super.setTag('Tickets/AnswerTicket')
    }
    setParams(data){
        super.setFormData(data)
    }
}
class TicketCloseRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod('POST')
    }
    setParams(data){
        super.setTag(`Tickets/CloseTicket/${data.id}`)

    }
}
export {
    TicketGetAllRequest,
    TicketGetByIdRequest,
    AnswerTicket,
    TicketCloseRequest
};
