<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="AllTickets">
      <!--  Close Ticket Modal   -->
      <b-modal
          id="modal-close"
          cancelTitle="Close"
          centered
          ok-title="Confirm"
          title="Close This Ticket"
          @ok="closeTicket"
      >
        <b-row>
          <b-col cols="12">
            <span>Are you sure you want to close this ticket ?</span>
          </b-col>
        </b-row>
      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >

        <b-row>
          <b-col class="mb-1" cols="12" md="12">
            <h1>Support</h1>

          </b-col>
          <b-col
              class="    mb-2 mb-md-0"
              cols="12"
              md="2"
          >
            <b-form-group
                label="Count"
            >
              <v-select
                  v-model="count"
                  :clearable="false"
                  :options="perPageOptions"
                  class="   "
                  dir="ltr"
              />
            </b-form-group>
          </b-col>
          <b-col
              class="d-flex flex-column   mb-2"
              cols="12"
              md="3"
          >
            <b-form-group
                label="Search By"
            >
              <Debouncer @setValue="setSearch"></Debouncer>
            </b-form-group>
          </b-col>
          <b-col
              class="d-flex flex-column   mb-2"
              cols="12"
              md="3"
          >
            <b-form-group
                label="Ticket Status"
            >
              <v-select
                  v-model="status"
                  :clearable="false"
                  :options="statusOptions"
                  :reduce="title => title.value"
                  class="per-page-selector "
                  label="title"
              />
            </b-form-group>
          </b-col>

          <b-col
              class="d-flex flex-column mb-2"
              cols="12"
              md="3"
          >
            <b-form-group
                label="Ticket Type"
            >
              <v-select
                  v-model="type"
                  :clearable="false"
                  :options="typeOptions"
                  :reduce="title => title.value"
                  class="per-page-selector"
                  label="title"
              />
            </b-form-group>
          </b-col>


          <b-col cols="12">
            <b-table
                ref="refUserListTable"
                :fields="myTableColumns"
                :items="AllTickets"
                bordered
                class="position-relative"
                empty-text="Nothing Found !"
                primary-key="id"
                responsive
                show-empty
                sort-by.sync
                striped
            >

              <!-- Column: delete -->

              <template #cell(userName)="data">
                <b-link :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }">
                <span>
                  {{ data.item.userName }}
                </span>
                </b-link>
              </template>

              <template #cell(createdAt)="data">
                {{ new Date(data.item.createdAt).toLocaleTimeString('en-US') }}
                -
                {{ new Date(data.item.createdAt).toLocaleTimeString('en-US') }}

              </template>

              <template #cell(lastUpdate)="data">
                {{ new Date(data.item.lastUpdate).toLocaleTimeString('en-US') }}
                -
                {{ new Date(data.item.lastUpdate).toLocaleTimeString('en-US') }}
              </template>

              <template #cell(type)="data">
                <span v-if="data.item.ticketType===0" class="badge pill text-white bg-primary">-</span>
                <span v-if="data.item.ticketType===1" class="badge pill text-white bg-primary">Technical Problems</span>
                <span v-if="data.item.ticketType===2" class="badge pill text-white bg-primary">Organization Functionality</span>
                <span v-if="data.item.ticketType===4" class="badge pill text-white bg-primary">Financial</span>
                <span v-if="data.item.ticketType===3" class="badge pill text-white bg-primary">Others</span>
                <span v-if="data.item.ticketType===5" class="badge pill text-white bg-primary">Report A Problem</span>
                <span v-if="data.item.ticketType===6" class="badge pill text-white bg-primary">User Review</span>
              </template>
              <template #cell(status)="data">
                <b-badge v-if="data.item.status === 1" v-b-modal.modal-close class="cursor-pointer" variant="secondary"
                         @click="setSelectedTicket(data.item)"
                >
                  Waiting For Support
                  <feather-icon icon="ChevronDownIcon"></feather-icon>
                </b-badge>
                <b-badge v-else-if="data.item.status === 2" v-b-modal.modal-close class="cursor-pointer"
                         variant="success" @click="setSelectedTicket(data.item)"
                >
                  Waiting For User
                  <feather-icon icon="ChevronDownIcon"></feather-icon>
                </b-badge>
                <b-badge v-else-if="data.item.status === 3" variant="danger">
                  Closed
                </b-badge>
              </template>
              <template #cell(Detail)="data">
                <b-link :to="`/apps/ticketDetail/${data.item.id}`" class="cursor-pointer">
                  <feather-icon class="text-primary" icon="EyeIcon" size="20"/>
                </b-link>
              </template>


            </b-table>

          </b-col>
          <b-col cols="12">
            <div class="mx-2 mb-2">
              <b-row>
                <!-- Pagination -->
                <b-col
                    class="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap"
                    cols="12"
                    sm="12"
                >
                  <b-pagination
                      v-model="currentPage"
                      :per-page="count"
                      :total-rows="totalCount"
                      class="mb-2 mb-md-0 mt-1 mt-sm-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon"
                                    size="18"
                                    style="transform: rotate(180deg)"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>


                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import { BBadge, BCard, BCol, BFormGroup, BLink, BOverlay, BPagination, BRow, BTable } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { TicketCloseRequest, TicketGetAllRequest } from '@/libs/Api/Ticket'
import Debouncer from '@/views/components/debouncer.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  title: 'Support',
  name: 'AllTickets',
  data() {
    return {
      overlay: false,
      AllTickets: null,
      totalCount: null,
      showDeleteModal: false,
      currentPage: 1,
      deleteItem: null,
      perPage: 10,
      CategoryName: '',
      status: '',
      type: '',
      selectedTicket: null,
      typeOptions: [
        {
          title: 'All',
          value: ''
        },

        {
          title: 'Technical Problems',
          value: 1
        },
        {
          title: 'Organization Functionality',
          value: 2
        },
        {
          title: 'Financial',
          value: 4
        },
        {
          title: 'Others',
          value: 3
        },
        {
          title: 'Report A Problem',
          value: 5
        },
        {
          title: 'User Review',
          value: 6
        },
      ],
      statusOptions: [
        {
          title: 'All',
          value: ''
        },
        {
          title: 'Waiting For Support',
          value: 1
        },
        {
          title: 'Waiting For User',
          value: 2
        },
        {
          title: 'Closed',
          value: 3
        },
      ],
      sortBy: '',
      TicketTypesId: [
        {
          option: 'Authorization',
          value: 1
        },
        {
          option: 'Support',
          value: 2
        },
        {
          option: 'Other',
          value: 3
        },
      ],
      TicketType: 0,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'id',
          label: '#',
          sortable: true
        },
        {
          key: 'userName',
          label: 'From',
          sortable: true

        },
        {
          key: 'title',
          label: 'title',
          sortable: true
        },
        {
          key: 'type',
          label: 'type',
          sortable: true
        },
        {
          key: 'status',
          label: 'status',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'create date',
          sortable: true
        },
        {
          key: 'lastUpdate',
          label: 'lastUpdate',
          sortable: true
        },
        {
          key: 'Detail',
          label: 'Detail',
        },
      ],
      pageNumber: 1,
      count: 10,
      search: '',
      SelectedTicket: null,
    }
  },
  async created() {
    await this.getAllTicket()
  },
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BFormGroup,
    BBadge,
    BPagination,
    BOverlay,
    vSelect,
    Debouncer
  },
  watch: {
    currentPage: function () {
      this.getAllTicket()
    },
    TicketType: function () {
      this.getAllTicket()
    },
    count: function () {
      this.getAllTicket()
    },
    status: function () {
      this.getAllTicket()
    },
    type: function () {
      this.getAllTicket()
    },
  },
  methods: {
    setSelectedTicket(item) {
      this.selectedTicket = item
    },
    async closeTicket() {
      let _this = this
      _this.overlay = true

      let ticketCloseRequest = new TicketCloseRequest(_this)
      ticketCloseRequest.setParams({ id: _this.selectedTicket.id })
      await ticketCloseRequest.fetch((content) => {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `Ticket Closed.`,
          },
        })
        _this.getAllTicket()
      }, (error) => {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `warning`,
            icon: 'CheckIcon',
            variant: 'danger',
            text: error.message,
          },
        })
        console.log(error)
      })
    },
    setSearch(data) {
      this.search = data
      this.getAllTicket()
    },
    async getAllTicket() {
      let _this = this
      _this.overlay = true
      let ticketGetAllRequest = new TicketGetAllRequest(_this)
      let data = {
        PageNumber: _this.currentPage,
        Count: _this.count,
        search: _this.search,
        ticketType: _this.type,
        status: _this.status
      }
      ticketGetAllRequest.setParams(data)
      await ticketGetAllRequest.fetch(function (content) {
        _this.overlay = false
        _this.AllTickets = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.overlay = false
        console.log(error)
      })
    },
    createJalaliDate(param) {
      if (param !== null) {
        return new Date(param).toLocaleDateString('fa-IR',)
      }
    },
  },
}
</script>

<style scoped>

</style>
